import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-from-class": "opacity-enter-from",
    "enter-active-class": "opacity-enter-active",
    "enter-to-class": "opacity-enter-to",
    "leave-from-class": "opacity-leave-from",
    "leave-active-class": "opacity-leave-active",
    "leave-to-class": "opacity-leave-to"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}