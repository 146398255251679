
//* Components */
import LoadingSpinner from "./LoadingSpinner.vue";
//* Packages */
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import http from "../../../utils/http-common";

//* Models */
import IGeneralTimeSlot from "../../../interfaces/IGeneralTimeSlot";
import ITimeSlot from "../../../interfaces/ITimeSlot";
import IAvailableBoats from "../../../interfaces/IAvailableBoats";
import IBoat from "../../../interfaces/IBoat";
import ICalendarDay from '@/interfaces/ICalendarDay';
import ICustomTimeSlot from '@/interfaces/ICustomTimeSlot';
import { toast } from 'vue3-toastify';

export default defineComponent({
    name: "BookingCard",
    components: {LoadingSpinner},
    data() {
        return {
            loading: true as boolean,
            generalTimeSlot: {} as IGeneralTimeSlot,
            availableBoats: [] as IAvailableBoats[],
            selectedCalendarDay: {} as ICalendarDay,
            selectedCategory: "" as string,
            timeSlots: [] as ITimeSlot[],
            selectedTimeSlot: null as unknown as ITimeSlot,
            minTime: null as string | null,
            available: [] as boolean[],
        };
    },
    props: {
        calendarDay: {
            type: Object as PropType<ICalendarDay>
        },
        category: {
            type: String
        }
    },
    methods: {
        convertDate(date: string | undefined): string {
            if (date) {
                return dayjs(date).format("DD/MM/YYYY");
            }
            return dayjs().format("DD/MM/YYYY");
        },
        checkIfAvailable(id: number): boolean {
            let isAvailable = this.getAmountAvailableBoats(this.selectedCategory, id);
            return isAvailable !== 0;
        },
        async getTimeSlots(): Promise<void> {
            this.availableBoats = [];

            if (this.selectedCalendarDay.customTimeSlot) {
                let customTimeSlots = await this.checkCustomTimeSlots();
                let slots = this.dayFoundInCustomTimeSlot(dayjs(this.selectedCalendarDay.date), customTimeSlots);
                this.timeSlots = this.sortTimeSlots(slots);

                // Use a loop to handle asynchronous calls
                for (const timeslot of this.timeSlots) {
                    timeslot.disabled = !(await this.checkAvailability(timeslot));
                    await this.getAvailableBoats(timeslot.id);
                }

                this.loading = false;
            } else {
                try {
                    const res = await http.get(`general_time_slots/day/${this.selectedCalendarDay.dayOfWeek}`);
                    this.timeSlots = this.sortTimeSlots(res.data.time_slots);

                    for (const timeslot of this.timeSlots) {
                        timeslot.disabled = !(await this.checkAvailability(timeslot));
                        await this.getAvailableBoats(timeslot.id);
                    }

                    this.loading = false;
                } catch (error) {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    });
                }
            }
        },

        getAmountAvailableBoats(category: string, id: number): number {
            if (!this.availableBoats) {
                return 0;
            }

            const availableBoatsForTimeslot: IAvailableBoats | undefined = this.availableBoats.find(
                (availableBoat: IAvailableBoats) => availableBoat.timeslotId === id
            );

            if (!availableBoatsForTimeslot || !availableBoatsForTimeslot.boats) {
                return 0;
            }

            const filteredBoats: IBoat[] = availableBoatsForTimeslot.boats.filter(
                (boat: IBoat) => boat.category === category
            );

            return filteredBoats.length;

        },
        getAvailableBoats(timeslotId: number): void {
            http.get(`available_boats/${this.selectedCalendarDay.date}/${timeslotId}`).then((res) => {
                const availability: IAvailableBoats = {
                    timeslotId: timeslotId,
                    boats: res.data
                }
                this.availableBoats.push(availability);
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                })
        },
        timeSlotParser(time: string): string {
            if (time.endsWith(":00")) {
                return time.slice(0, -3);
            }
            return time;
        },
        sortTimeSlots(timeslots: ITimeSlot[]): ITimeSlot[] {
            if (!timeslots) {
                return [] as ITimeSlot[];
            }

            return timeslots.sort((x, y) => {
                const timeA = new Date(`1970-01-01T${x.start_time}`);
                const timeB = new Date(`1970-01-01T${y.start_time}`);
                return timeA.getTime() - timeB.getTime();
            });
        },
        handleReservation(slot: ITimeSlot): void {
            http.post('timeslots/check-availability', { slot: slot, date: this.selectedCalendarDay.date }).then((res) => {
                if(this.checkIfAvailable(slot.id)) {
                    this.selectedTimeSlot = slot;
                    this.$emit('handleReservation', slot)
                } else {
                    toast(this.$t('ERROR_NO_AVAILABLE_BOATS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                }
            })
            .catch((err) => {
                toast(this.$t('ERROR_NO_AVAILABLE_BOATS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
            })
        },
        async checkAvailability(slot: ITimeSlot): Promise<boolean> {
            try {
                const response = await http.post('timeslots/check-availability', { slot: slot, date: this.selectedCalendarDay.date });
                return response.data.available;
            } catch (err) {
                return false;
            }
        },
        async checkCustomTimeSlots(): Promise<ICustomTimeSlot[]> {
            const startDate = dayjs(this.selectedCalendarDay.date).startOf('month').format('YYYY-MM-DD');
            const endDate   = dayjs(this.selectedCalendarDay.date).endOf('month').format('YYYY-MM-DD');

            try {
                const res = await http.get(`custom_time_slot/dates`, {
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    }
                });
                return res.data.customTimeSlots;
            } catch (error) {
                throw new Error(`Error fetching custom time slots: ${error}`);
            }
        },
        dayFoundInCustomTimeSlot(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): ITimeSlot[] {
            for (const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date);
                const end   = dayjs(customTimeSlot.end_date);
                if (dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    return customTimeSlot.time_slots;
                }
            }
            return [];
        },
        getMinimalTimeBeforeBookingSlot(): void {
            http.get('settings/key/MINIMUM_TIME_BEFORE_BOOK').then((res) => {
                this.minTime = res.data.value;
            })
        },
    },
    watch: {
        calendarDay: {
            handler(calendarDay: ICalendarDay) {
                if (calendarDay) {
                    this.selectedCalendarDay = calendarDay
                    this.getTimeSlots();
                }
            },
            immediate: true,
        },
        category: {
            handler(category: string) {
                if (category) {
                    this.selectedCategory = category
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.getMinimalTimeBeforeBookingSlot();
    }
});
