
import { defineComponent } from "vue";

export default defineComponent({
    name: "ProgressBar",
    data() {
        return {
            currentStep: 0 as number,
            steps: [
                {
                    name: 'PROGRESS_BAR_DETAILS',
                    symbol: 'person',
                    unlocked: true
                },
                {
                    name: 'PROGRESS_BAR_DATE',
                    symbol: 'schedule',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_ARRANGEMENTS',
                    symbol: 'menu_book',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_DRINKS',
                    symbol: 'water_full',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_EXTRAS',
                    symbol: 'fastfood',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_PAYMENT',
                    symbol: 'credit_card',
                    unlocked: false
                }
            ],
        }
    },
    methods: {
        jumpTo(index: number): void {
            if(this.steps[index].unlocked) {
                this.$emit('setStep', index);
            }
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement;
            button.classList.remove("grow-shrink");
            void button.offsetWidth;
            button.classList.add("grow-shrink");
        },
    },
    props: {
        stepIndex: Number
    },
    watch: {
        stepIndex: {
            handler(index) {
                if (index) {
                    this.currentStep = index;
                    this.steps[index].unlocked = true;
                }
            },
            immediate: true,
        },
    },
    mounted(): void {
        this.stepIndex ? this.currentStep = this.stepIndex : this.currentStep = 0;
    }
});
