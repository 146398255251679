import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BookingView from '@/components/frontend/BookingView.vue'
import { checkAuth } from '@/utils/route-middelware';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/bbq-donut-boat-booking'
    },
    {
        path: '/bbq-donut-boat-booking',
        name: 'booking-overview',
        component: BookingView
    },
    {
        path: '/bbq-donut-boat-booking/order-confirmation',
        name: 'order-confirmation',
        component: () => import('@/components/frontend/booking-process/OrderConfirmationDetails.vue')
    },
    {
        path: '/login',
        name: 'admin-login-overview',
        component: () => import('../views/auth/LoginOverview.vue')
    },
    {
        path: '/admin/overview',
        name: 'admin-overview',
        component: () => import('../views/backend/AdminOverview.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/admin/orders',
        name: 'admin-orders',
        component: () => import('../views/backend/AdminOrderOverview.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/admin/development',
        name: 'admin-development',
        component: () => import('../views/backend/AdminDevelopmentOverview.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/admin/settings',
        name: 'admin-settings',
        component: () => import('../views/backend/AdminSettingsOverview.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/admin/arrangements',
        name: 'admin-arrangements',
        component: () => import('../views/backend/AdminArrangementsOverview.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/drinks',
        name: 'admin-drinks',
        component: () => import('../views/backend/AdminDrinksOverview.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/dishes',
        name: 'admin-dishes',
        component: () => import('../views/backend/AdminDishesOverview.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/boats',
        name: 'admin-boats',
        component: () => import('../views/backend/AdminBoatOverview.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/coupons',
        name: 'admin-coupons',
        component: () => import('../views/backend/AdminCouponOverview.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/timeslots',
        name: 'admin-timeslots',
        component: () => import('../views/backend/AdminTimeslotOverview.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'redirect',
                redirect: {name: 'GeneralTimeSlots'}
            },
            {
                path: 'general',
                name: 'GeneralTimeSlots',
                component: () => import('../components/backend/PartialViews/TimeSlots.vue'),
            },
            {
                path: 'custom',
                name: 'CustomTimeSlots',
                component: () => import('../components/backend/PartialViews/CustomTimeSlots.vue'),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        checkAuth(to, from, next);
    } else {
        next();
    }
})

export default router
