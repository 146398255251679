import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6e200a01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "material-symbols-outlined symbol-size progress-item-symbol" }
const _hoisted_4 = { class: "text progress-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "progress-item"
      }, [
        _createElementVNode("div", {
          onClick: (event) => { _ctx.jumpTo(index); _ctx.animateButton(event) },
          class: "item-wrapper pointer",
          style: _normalizeStyle({ backgroundColor: _ctx.steps[index].unlocked ? '#b22b00' : '' })
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(step.symbol), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(step.name)), 1)
        ], 12, _hoisted_2)
      ]))
    }), 128))
  ]))
}