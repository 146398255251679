

//* Components /
import BoatDropdown from '@/components/partials/custom-fields/BoatDropdown.vue';
import CapacityDropdown from '@/components/partials/custom-fields/CapacityDropdown.vue';
import { VueTelInput } from 'vue-tel-input'

//* Packages /
import { defineComponent } from 'vue'
import 'vue-tel-input/vue-tel-input.css';

//* Interfaces /
import IReservation from '@/interfaces/IReservation';
import IBoat from '@/interfaces/IBoat';
import http from '@/utils/http-common';
import ICustomer from '@/interfaces/ICustomer';
import { toast } from 'vue3-toastify';
import { parsePhoneNumberFromString, CountryCode} from 'libphonenumber-js';

export default defineComponent({
    name: "BookingDetails",
    components: {
        BoatDropdown, CapacityDropdown, VueTelInput
    },
    data() {
        return {
            customer: {} as ICustomer,
            reservation: {} as IReservation,
            boats: [] as IBoat[],
            selectedCategory: null as unknown as string,
            guests: 0 as number,
            errors: {} as Record<string, string>,
            selectedCountry: 'NL' as CountryCode | undefined,
            isDirty: false as boolean,
            details: null as null | boolean,
        }
    },
    methods: {
        getBoats(): void {
            http.get(`boats`, { params: { pagination: false } }).then((res) => {
                this.boats = res.data;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        selectedOption(category: string) {
            this.selectedCategory = category;
            this.guests = 0;
            this.validateDropdownFields('boat');
            if(this.isDirty) {
                this.$emit('setCategory', this.selectedCategory)
                this.$emit('setCapacity', this.guests)
            }
        },
        selectedCapacity(guests: number) {
            this.guests = guests;
            this.validateDropdownFields('capacity')
            if(this.isDirty) {
                this.$emit('setCapacity', this.guests)
            }
        },
        formValid(): boolean {
            return !Object.keys(this.errors).length;
        },
        nextStep(): void {
            this.validateAll();
            if(!this.formValid()) {
                toast(this.$t('ERROR_MISSING_REQUIRED_FIELDS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
                return;
            }
            if(!this.customer.id) {
                http.post(`customers`, this.customer).then((res) => {
                    if(res.data.id) {
                        localStorage.setItem('token', res.data.token);
                        this.customer = res.data
                        this.isDirty = true;
                        this.$emit('setDetails', this.customer, this.selectedCategory, this.guests);
                    }
                })
                    .catch(() => {
                        toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000
                        })
                    })
            }
            else {
                this.$emit('setDetails', this.customer, this.selectedCategory, this.guests);
            }
        },
        handlePhoneNumberChange(country: CountryCode) {
            this.selectedCountry = country;
        },
        validateCustomerFields(field: keyof ICustomer): void {
            const validators: Partial<Record<keyof ICustomer, () => string | null>> = {
                name: this.validateName,
                surname: this.validateSurname,
                email: this.validateEmail,
                phone_number: this.validatePhone,
            };

            if(validators[field]) {
                const errorMessage = validators[field]?.();

                if (errorMessage) {
                    this.errors[field] = errorMessage;
                } else {
                    delete this.errors[field];
                }
            }
        },
        validateDropdownFields(field: string): void {
            const validators: Record<string, () => string | null> = {
                boat: this.validateBoat,
                capacity: this.validateCapacity
            }

            if(validators[field]) {
                const errorMessage = validators[field]?.();

                if (errorMessage) {
                    this.errors[field] = errorMessage;
                } else {
                    delete this.errors[field];
                }
            }
        },
        validateName(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.customer.name) {
                return this.$t('VALIDATION_NAME_REQUIRED');
            }
            return null;
        },
        validateSurname(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.customer.surname) {
                return this.$t('VALIDATION_SURNAME_REQUIRED');
            }
            return null;
        },
        validateEmail(): string | null {
            if (!this.customer.email) {
                return this.$t('VALIDATION_EMAIL_REQUIRED');
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.customer.email)) {
                return this.$t('VALIDATION_EMAIL_INVALID');
            }
            return null;
        },
        validatePhone(): string | null {
            if (!this.customer.phone_number) {
                return this.$t('VALIDATION_PHONE_REQUIRED');
            }

            const phoneNumber = parsePhoneNumberFromString(this.customer.phone_number);
            const isValid = phoneNumber && phoneNumber.isValid();
            if(!isValid) {
               return this.$t('VALIDATION_PHONE_INVALID');
            }

            return null;
        },
        validateBoat(): string | null {
            if (!this.selectedCategory) {
                return this.$t('VALIDATION_BOAT_REQUIRED');
            }
            return null;
        },
        validateCapacity(): string | null {
            if (!this.guests || this.guests === 0) {
                return this.$t('VALIDATION_CAPACITY_REQUIRED');
            }
            return null;
        },
        validateAll(): void {
            this.validateCustomerFields('name');
            this.validateCustomerFields('surname');
            this.validateCustomerFields('email');
            this.validateCustomerFields('phone_number');
            this.validateDropdownFields('boat');
            this.validateDropdownFields('capacity')
        }
    },
    props: {
        requireDetails: String,
    },

    watch: {
        requireDetails: {
            handler(require: string) {
                if (require) {
                    this.details = require === '1'
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.getBoats();
    },
})
