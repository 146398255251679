
import { defineComponent, PropType } from 'vue'
import ITimeSlot from '@/interfaces/ITimeSlot';
import ICustomer from '@/interfaces/ICustomer';
import IArrangement from '@/interfaces/IArrangement';
import IDrink from '@/interfaces/IDrink';
import IDish from '@/interfaces/IDish';
import LoadingSpinner from '@/components/partials/custom-fields/LoadingSpinner.vue';
import dayjs from 'dayjs';
import http from '@/utils/http-common';
import ICoupon from '@/interfaces/ICoupon';
import ISetting from '@/interfaces/ISetting';
import { toast } from 'vue3-toastify';
import { AxiosError } from 'axios';

export default defineComponent({
    name: "PaymentDetails",

    // eslint-disable-next-line vue/no-unused-components
    components: {LoadingSpinner},
    data() {
        return {
            discount: '' as string,
            selectedCoupon: {} as ICoupon,
            selectedDate: '' as string,
            selectedSlot: {} as ITimeSlot,
            selectedCustomer: {} as ICustomer,
            selectedBoatCategory: '' as string,
            selectedArrangements: [] as IArrangement[],
            selectedDrinks: [] as IDrink[],
            selectedExtraDrinks: [] as IDrink[],
            selectedExtraDishes: [] as IDish[],
            drinksLoaded: false as boolean,
            dishesLoaded: false as boolean,
            paymentStep: 0 as number,
            drinksAmount: null as null | number,
            selectedGuests: 0 as number,
            priceSetting: {} as ISetting,
            errors: {} as Record<string, string>,
            details: null as null | boolean,
        }
    },
    methods: {
        calculateTotalArrangementCost(): number {
            let cost = 0;
            this.selectedArrangements.forEach((arrangement) => {
                cost += Number(arrangement.price)
            })
            return cost;
        },
        calculateTotalExtraDrinkCost(): number {
            let cost = 0;
            this.selectedExtraDrinks.forEach((drink) => {
                cost += Number(drink.price)
            })
            return cost;
        },
        calculateTotalExtraDishesCost(): number {
            let cost = 0;
            this.selectedExtraDishes.forEach((dish) => {
                cost += Number(dish.price)
            })
            return cost;
        },
        calculateTotalCost(): number {
            let cost = 0
            cost += this.calculateTotalArrangementCost();
            cost += this.calculateTotalExtraDrinkCost();
            cost += this.calculateTotalExtraDishesCost();
            cost += parseFloat(String(this.priceSetting.value))

            return cost;
        },
        calculateDiscount(): number {
            if(!this.selectedCoupon.id) {
                return 0
            }

            if(this.selectedCoupon.is_coupon) {
                return this.selectedCoupon.amount;
            }

            else {
                return this.calculateTotalCost() * (this.selectedCoupon.discount_percentage / 100)
            }
        },
        currentArrangementOverview(): { arrangement: IArrangement, amount: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedArrangements.forEach((arrangement: { id: number }) => {
                const arrangementId = arrangement.id;

                if (ids[arrangementId]) {
                    ids[arrangementId]++;
                } else {
                    ids[arrangementId] = 1;
                }
            });

            return Object.keys(ids).map((arrangementId) => {
                const count       = ids[arrangementId];
                const arrangement = this.selectedArrangements.find(
                    (arr) => arr.id === parseInt(arrangementId)
                ) as IArrangement;

                return {arrangement: arrangement, amount: count};
            });
        },
        currentDrinkOverview(): { drink: IDrink, amount: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id;

                if (ids[drinkId]) {
                    ids[drinkId]++;
                } else {
                    ids[drinkId] = 1;
                }
            });

            return Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId];
                const drink = this.selectedDrinks.find(
                    (arr) => arr.id === parseInt(drinkId)
                ) as IDrink;
                return {drink: drink, amount: count};
            });
        },
        currentExtraDrinkOverview(): { drink: IDrink, amount: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedExtraDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id;

                if (ids[drinkId]) {
                    ids[drinkId]++;
                } else {
                    ids[drinkId] = 1;
                }
            });

            return Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId];
                const drink = this.selectedExtraDrinks.find(
                    (arr) => arr.id === parseInt(drinkId)
                ) as IDrink;
                return {drink: drink, amount: count};
            });
        },
        currentDishesOverview(): { dish: IDish, amount: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedExtraDishes.forEach((dish: { id: number }) => {
                const dishId = dish.id;

                if (ids[dishId]) {
                    ids[dishId]++;
                } else {
                    ids[dishId] = 1;
                }
            });

            return Object.keys(ids).map((dishId) => {
                const count     = ids[dishId];
                const dish      = this.selectedExtraDishes.find(
                    (arr) => arr.id === parseInt(dishId)
                ) as IDish;
                return {dish: dish, amount: count};
            });
        },
        timeSlotParser(time: string): string {
            if(!time) {
                return ''
            }
            if (time.endsWith(":00")) {
                return time.slice(0, -3);
            }
            return time;
        },
        async createPayment() {
            if(!this.validateArrangements()) {
                return;
            }

            if(!this.validateDrinks()) {
                return;
            }

            const reservationData = {
                boat_category: this.selectedBoatCategory,
                timeslot_id: this.timeslot?.id,
                customer_id: this.customer?.id,
                reservation_date: this.selectedDate,
                arrangements: this.selectedArrangements.map(arr => arr.id),
                drinks: this.selectedDrinks.map(drink => drink.id),
                extra_drinks: this.selectedExtraDrinks.map(drink => drink.id),
                dishes: this.selectedExtraDishes.map(dish => dish.id),
                coupon_id: this.selectedCoupon.id,
                guests: this.selectedGuests,
            }
            const totalCost = this.calculateTotalCost().toFixed(2).toString();
            const vatAmount = this.calculateTotalCost() * (9 / 109)
            const payment = {
                amount: {
                    value: totalCost,
                    currency: "EUR",
                },
                billingAddress: {
                    streetAndNumber: `${this.customer?.street_name} ${this.customer?.house_number}`,
                    postalCode: this.customer?.zipcode,
                    city: this.customer?.city,
                    country: "nl",
                    givenName: this.customer?.name,
                    familyName: this.customer?.surname,
                    email: this.customer?.email,
                },
                shippingAddress: {
                    streetAndNumber: `${this.customer?.street_name} ${this.customer?.house_number}`,
                    postalCode: this.customer?.zipcode,
                    city: this.customer?.city,
                    country: "nl",
                    givenName: this.customer?.name,
                    familyName: this.customer?.surname,
                    email: this.customer?.email,
                },
                locale: "en_US",
                lines: [
                    {
                        sku: "1000000",
                        description: "#1000000",
                        quantity: 1,
                        vatRate: "9.00",
                        unitPrice: {
                            currency: "EUR",
                            value: totalCost
                        },
                        totalAmount: {
                            currency: "EUR",
                            value: totalCost
                        },
                        vatAmount: {
                            currency: "EUR",
                            value: vatAmount.toFixed(2).toString()
                        }
                    }
                ]
            }
            try {
                const reservation = await http.post('reservations', reservationData);

                const metaData = { metadata: { order_id: reservation.data.id }}
                const paymentData = {...payment, ...metaData, description: reservation.data.description, couponId: this.selectedCoupon.id}

                const paymentResponse = await http.post(`payment`, paymentData);
                window.location.href = paymentResponse.data.checkoutUrl;
            } catch (error: unknown) {
                console.log(error);
                if (error instanceof AxiosError && error.response) {
                    toast(this.$t(String(error.response.data.error)), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    });
                } else {
                    console.error("An unknown error occurred:", error);
                }
            }
        },
        getDay(): string {
            return dayjs(this.selectedDate).format('dddd')
        },
        getFormattedDate(): string {
            let date = dayjs(this.selectedDate);

            const day = date.format('D');
            const month = date.format('MMMM');

            return `${day} ${month}`
        },
        calculateTax(): number {
            return (this.calculateTotalCost() - this.calculateDiscount()) * 0.09;
        },
        prevPaymentStep(): void {
            this.paymentStep -= 1;
        },
        prevStep(): void {
            this.$emit('prevStep')
        },
        nextStep(): void {
            this.validateAll();
            if(!this.formValid()) {
                return;
            }
            if(this.customer) {
                const token = localStorage.getItem('token');
                const customer = {...this.customer, token}
                http.put(`customers/${this.customer.id}`, customer)
                this.paymentStep++;
            }
        },
        formattedPrice(price: number): string {
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price.toFixed(2)}`;
                } else {
                    return `${price.toFixed(2)} ${item}`;
                }
            }
            return `${price}`;
        },
        validateCustomerFields(field: keyof ICustomer): void {
            const validators: Partial<Record<keyof ICustomer, () => string | null>> = {
                name: this.validateName,
                surname: this.validateSurname,
                email: this.validateEmail,
                phone_number: this.validatePhone,
                city: this.validateCity,
                zipcode: this.validateZipcode,
                street_name: this.validateStreetName,
                house_number: this.validateHouseNumber
            };

            if(validators[field]) {
                const errorMessage = validators[field]?.();

                if (errorMessage) {
                    this.errors[field] = errorMessage;
                } else {
                    delete this.errors[field];
                }
            }
        },
        formValid(): boolean {
            return !Object.keys(this.errors).length;
        },
        validateAll(): void {
            this.validateCustomerFields('name');
            this.validateCustomerFields('surname');
            this.validateCustomerFields('email');
            this.validateCustomerFields('phone_number');
            this.validateCustomerFields('city');
            this.validateCustomerFields('zipcode');
            this.validateCustomerFields('street_name');
            this.validateCustomerFields('house_number');
        },
        validateName(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.name) {
                return this.$t('VALIDATION_NAME_REQUIRED');
            }
            return null;
        },
        validateSurname(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.surname) {
                return this.$t('VALIDATION_SURNAME_REQUIRED');
            }
            return null;
        },
        validateEmail(): string | null {
            if (!this.selectedCustomer.email) {
                return this.$t('VALIDATION_EMAIL_REQUIRED');
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.selectedCustomer.email)) {
                return this.$t('VALIDATION_EMAIL_INVALID');
            }
            return null;
        },
        validateCity(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.city) {
                return this.$t('VALIDATION_CITY_REQUIRED');
            }
            return null;
        },
        validateZipcode(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.zipcode) {
                return this.$t('VALIDATION_ZIPCODE_REQUIRED');
            }
            return null;
        },
        validateStreetName(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.street_name) {
                return this.$t('VALIDATION_STREET_NAME_REQUIRED');
            }
            return null;
        },
        validateHouseNumber(): string | null {
            if(!this.details) {
                return null;
            }

            if (!this.selectedCustomer.house_number) {
                return this.$t('VALIDATION_HOUSE_NUMBER_REQUIRED');
            }
            return null;
        },
        validatePhone(): string | null {
            if (!this.selectedCustomer.phone_number) {
                return this.$t('VALIDATION_PHONE_REQUIRED');
            }
            const phonePattern = /^[+]?[0-9\s-]{7,15}$/;
            if (!phonePattern.test(this.selectedCustomer.phone_number)) {
                return this.$t('VALIDATION_PHONE_INVALID');
            }
            return null;
        },
        validateArrangements(): boolean {
            if(this.selectedGuests && this.selectedArrangements.length !== this.selectedGuests) {
                if(this.selectedGuests - this.selectedArrangements.length < 0) {
                    toast(this.$t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                }
                else {
                    toast(this.$t(this.selectedGuests - this.selectedArrangements.length != 1 ? 'PAYMENT_DETAILS_MISSING_ARRANGEMENTS' : 'PAYMENT_DETAILS_MISSING_ARRANGEMENT' , {amount: this.selectedGuests - this.selectedArrangements.length}), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                }

                return false;
            }

            return true;
        },
        validateDrinks(): boolean {
            if(!this.drinksAmount) {
                return false
            }
            if(this.drinksAmount && this.selectedDrinks.length !== this.drinksAmount) {
                if(this.drinksAmount - this.selectedDrinks.length < 0) {
                    toast(this.$t('PAYMENT_DETAILS_TO_MUCH_DRINKS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                }
                else {
                    toast(this.$t(this.drinksAmount - this.selectedDrinks.length != 1 ? 'PAYMENT_DETAILS_MISSING_DRINKS' : 'PAYMENT_DETAILS_MISSING_DRINK' , {amount: this.drinksAmount - this.selectedDrinks.length}), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                }
                return false;
            }

            return true;
        },
        getPriceSetting(index: number): void {
            http.get('settings/key/PERSON_PRICE_SETTINGS_' + index).then((res) => {
                this.priceSetting = res.data;
            })
        },
        calculateAmountOfDrinks(): void {
            const amount: number = this.selectedArrangements.reduce(
                (sum: number, arrangement: IArrangement) => {
                    return sum + (arrangement.drink_amount || 0);
                },
                0
            );

            if (this.selectedArrangements.length === this.guests) {
                this.drinksAmount = amount;
            }
        },
        validateDiscountCode() {
            if(this.discount.length === 0) {
                return;
            }

            http.get(`coupons/validate/${this.discount}`)
                .then(res => {
                    if (res.data.valid) {
                        this.selectedCoupon = res.data.coupon;
                        this.errors['discount_code'] = ""
                    } else {
                        this.selectedCoupon = {} as ICoupon;
                        this.errors['discount_code'] = this.$t('ERROR_DISCOUNT_CODE_INVALID');
                    }
                })
                .catch(() => {
                    this.errors['discount_code'] = this.$t('ERROR_DISCOUNT_CODE_INVALID');
                });
        },
    },
    props: {
        date: {
            type: String
        },
        timeslot: {
            type: Object as PropType<ITimeSlot>
        },
        customer: {
            type: Object as PropType<ICustomer>
        },
        boatCategory: {
            type: String
        },
        requireDetails: String,
        arrangements: {
            type: Object as PropType<IArrangement[]>
        },
        guests: {
            type: Number,
        },
        drinks: {
            type: Object as PropType<IDrink[]>
        },
        extraDishes: {
            type: Object as PropType<IDish[]>
        },
        extraDrinks: {
            type: Object as PropType<IDrink[]>
        },
    },
    watch: {
        date: {
            handler(date: string) {
                if (date) {
                    this.selectedDate = date;
                }
            },
        },
        timeslot: {
            handler(timeslot: ITimeSlot) {
                if (timeslot) {
                    this.selectedSlot = timeslot;
                }
            },
        },
        customer: {
            handler(customer: ICustomer) {
                if (customer) {
                    this.selectedCustomer = customer;
                }
            },
        },
        guests: {
            handler(guests: number) {
                if(guests) {
                    this.selectedGuests = guests
                    this.getPriceSetting(guests);
                }
            }
        },
        boatCategory: {
            handler(category: string) {
                if (category) {
                    this.selectedBoatCategory = category;
                }
            },
        },
        arrangements: {
            handler(arrangements: IArrangement[]) {
                if (arrangements) {
                    this.selectedArrangements = arrangements;
                    this.calculateAmountOfDrinks();
                }

            },
            deep: true
        },
        drinks: {
            handler(drinks: IDrink[]) {
                if (drinks) {
                    this.selectedDrinks = drinks;
                }
            },
            deep: true
        },
        extraDishes: {
            handler(dishes: IDish[]) {
                if (dishes) {
                    this.selectedExtraDishes = dishes;
                    this.dishesLoaded        = true;
                }
            },
            deep: true
        },
        extraDrinks: {
            handler(drinks: IDrink[]) {
                if (drinks) {
                    this.selectedExtraDrinks = drinks;
                    this.drinksLoaded        = true;
                }
            },
            deep: true
        },
        requireDetails: {
            handler(require: string) {
                if (require) {
                    this.details = require === '1'
                }
            },
            immediate: true,
        },
    },
})
