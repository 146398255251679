import { createStore, Store } from 'vuex';
import IUser from '@/interfaces/IUser';
import IState from '@/interfaces/IState';
import { InjectionKey } from 'vue';

export const key: InjectionKey<Store<IState>> = Symbol()

const store = createStore<IState>({
    state: {
        user: null, // Initially no user is set
    },
    mutations: {
        setUser(state, user: IUser) {
            state.user = user;
        },
    },
    getters: {
        isAdmin(state): boolean {
            return state.user ? state.user.role === 'admin' : false;
        },
        isEmployee(state): boolean {
            return state.user ? state.user.role === 'employee' : false;
        },
    },
});

export default store;