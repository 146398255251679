
//* Components */
import { defineComponent, PropType } from "vue";
//* Packages */
import IBoat from "../../../interfaces/IBoat";
//* Models */

export default defineComponent({
    name: "BoatDropdown",
    components: {},
    data() {
        return {
            selectedOption: "" as string,
            showOptions: false as boolean,
            options: [] as Array<string>,
        };
    },
    props: {
        boats: {
            type: Array as PropType<IBoat[]>,
        },
        onlyDropDown: Boolean,
        zIndex: Number,
        backgroundColor: {
            type: String,
            default: "",
        },
    },
    methods: {
        toggleDropdown() {
            this.showOptions = !this.showOptions;
        },
        selectOption(option: string) {
            let parsedOption    = this.parseCategory(option);
            this.selectedOption = parsedOption;
            this.$emit("selected", parsedOption);
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false;
            }
        },
        parseCategory(option: string): string {
            const result = option.match(/(.*?),/);
            result
                ? (option = result[1].replace(/\s*boat\s*/, ""))
                : (option = "");
            return result ? result[1].replace(/\s*boat\s*/, "") : "";
        },
        distinctCategories(boats: IBoat[]) {
            const distinctBoats: { [key: string]: string } = {};

            boats.forEach((boat) => {
                if (!distinctBoats[boat.category]) {
                    distinctBoats[boat.category] = `${boat.category} boat, max ${boat.capacity} guests`;
                }
            });

            return Object.values(distinctBoats);
        },
    },
    watch: {
        boats: {
            handler(boats) {
                this.options = this.distinctCategories(boats);
            },
            immediate: true,
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
});
